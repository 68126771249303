import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/atlassian/pipelines/agent/build/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Props, Playground } from 'docz';
import { Modal } from '../js/components/Modal';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "modal"
    }}>{`Modal`}</h1>
    <p><inlineCode parentName="p">{`import { Modal } from '@foodsby/nutrient'`}</inlineCode></p>
    <p>{`This helper component just provides the basic styling for a modal, and some minor functionality. It is nearly feature free, so make sure you provide a method to `}<inlineCode parentName="p">{`onCancel`}</inlineCode>{` that will hide the modal when the user clicks off the modal contents, or on the close button. Optionally you can pass `}<inlineCode parentName="p">{`showCancel="false"`}</inlineCode>{` to prevent the modal from being dismissed. This is useful if you have a non-dismissable component in the modal. `}<inlineCode parentName="p">{`target`}</inlineCode>{` is the ID of an element on the page that you want to mount the modal to.`}</p>
    <p>{`The modal is mounted using `}<inlineCode parentName="p">{`React.CreatePortal`}</inlineCode>{`, so it doesn't even need to be in your component, or even in your root at all. In-fact, you can just add an extra empty div to the `}<inlineCode parentName="p">{`index.html`}</inlineCode>{` or if you like. The default is `}<inlineCode parentName="p">{`id="modal"`}</inlineCode>{`.`}</p>
    <p>{`You can also pass custom wrapper classes via `}<inlineCode parentName="p">{`className`}</inlineCode>{` and a styles object via `}<inlineCode parentName="p">{`styles`}</inlineCode>{`.`}</p>
    <p>{`Additionally you can change the `}<inlineCode parentName="p">{`maxWidth`}</inlineCode>{` and `}<inlineCode parentName="p">{`maxHeight`}</inlineCode>{` by passing in those properties (the default `}<inlineCode parentName="p">{`maxWidth`}</inlineCode>{` is `}<inlineCode parentName="p">{`768px`}</inlineCode>{`).`}</p>
    <h2 {...{
      "id": "properties"
    }}>{`Properties`}</h2>
    <Props of={Modal} mdxType="Props" />
    <h2 {...{
      "id": "code-example"
    }}>{`Code Example`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import React, { Component } from 'react'
import { Modal, Button } from '@foodsby/nutrient'

export default class Demo extends Component {
  state = { showModal: false }

  showModal = evt => {
    evt.preventDefault()
    this.setState({ showModal: true })
  }

  hideModal = () => {
    this.setState({ showModal: false })
  }

  render() {
    const { showModal } = this.state
    return (
      <div>
        <div id="modal" />
        <Button onClick={this.showModal} className="primary">
          Show Modal!
        </Button>
        <Modal {...this.props} onCancel={this.hideModal} showModal={showModal}>
          <h1>I'm a modal!</h1>
        </Modal>
      </div>
    )
  }
}
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      