import React, { Component } from 'react'
import { createPortal } from 'react-dom'
import PropTypes from 'prop-types'
import cn from 'classnames'
import styled from 'styled-components'

const StyledModal = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 99999;
  transition: ${props => props.theme.transition};
  pointer-events: auto;
  overflow-y: auto;
`

const StyledModalInner = styled.div`
  max-width: 768px;
  margin: 0 auto;
  position: relative;
  padding: 32px;
`
const StyledModalClose = styled.button`
  position: absolute;
  right: 16px;
  top: 16px;
  background-color: ${props => props.theme.color.brandPrimary};
  border-radius: 50%;
  border: none;
  box-shadow: 4px 4px 6px rgba(50, 50, 50, 0.5);
  color: #fff;
  cursor: pointer;
  font-size: 55px;
  font-weight: 400;
  height: 50px;
  width: 50px;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0;
  margin: 0;
`

const ModalCloseInner = styled.span`
  line-height: 50px;
  margin: 0;
  padding: 0;
  width: 50px;
  line-height: 50px;
  border-radius: 50%;

  &:hover {
    text-decoration: none;
  }
`

const StyledModalCard = styled.div`
  margin: 0 auto;
  background-color: white;
  padding: 32px;
  border: 1px solid rgba(0, 0, 0, 0.06);
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px;
`

const SimpleModal = ({
  className,
  styles,
  onCancel,
  id,
  maxWidth,
  maxHeight,
  showCancel,
  children
}) => (
  <StyledModal
    className={cn(className, { modal: true })}
    style={styles}
    onClick={onCancel}
    id={id}
  >
    <StyledModalInner
      className="modal-inner"
      onClick={event => {
        event.stopPropagation()
      }}
      style={Object.assign(
        {},
        maxWidth && { maxWidth },
        maxHeight && { maxHeight }
      )}
    >
      {showCancel && (
        <StyledModalClose className="modal-close" onClick={onCancel}>
          <ModalCloseInner className="modal-close_inner">
            &times;
          </ModalCloseInner>
        </StyledModalClose>
      )}
      <StyledModalCard>{children}</StyledModalCard>
    </StyledModalInner>
  </StyledModal>
)

export class Modal extends Component {
  componentDidUpdate(prevProps, prevState) {
    if (this.props.showModal) {
      document.body.classList.add('noscroll')
    }
    if (prevProps.showModal && !this.props.showModal) {
      document.body.classList.remove('noscroll')
    }
  }
  componentWillUnmount() {
    document.body.classList.remove('noscroll')
  }

  render() {
    const {
      children,
      className,
      id,
      maxHeight,
      maxWidth,
      onCancel,
      showCancel = true,
      showModal = true,
      styles,
      target = 'modal'
    } = this.props

    return (
      <>
        {showModal &&
          (target ? (
            createPortal(
              <SimpleModal
                className={className}
                styles={styles}
                onCancel={onCancel}
                id={id}
                maxWidth={maxWidth}
                maxHeight={maxHeight}
                showCancel={showCancel}
              >
                {children}
              </SimpleModal>,
              document.getElementById(target)
            )
          ) : (
            <SimpleModal
              className={className}
              styles={styles}
              onCancel={onCancel}
              id={id}
              maxWidth={maxWidth}
              maxHeight={maxHeight}
              showCancel={showCancel}
            >
              {children}
            </SimpleModal>
          ))}
      </>
    )
  }
}

Modal.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  onCancel: PropTypes.func,
  className: PropTypes.string,
  style: PropTypes.object,
  maxHeight: PropTypes.string,
  maxWidth: PropTypes.string,
  showCancel: PropTypes.bool,
  showModal: PropTypes.bool
}
export default Modal
